import './App.css';
import OvenPlayer from 'ovenplayer';
import {Button, Container, Paper, Snackbar, Toolbar, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {useContext, useEffect, useRef, useState} from "react";
import {UserContext} from "./App";
import {Redirect, useParams} from "react-router-dom";
import axios from "axios";
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(10),
    },
}));


function Tv() {
    const classes = useStyles();
    const {user} = useContext(UserContext);
    const player = useRef(null);
    const {streamId} = useParams();

    useEffect(() => {
        //if (!user.isLogged) return;
        axios.get(`https://tramix.ovh/api/discord/tv/${user.sessionId}/${streamId}`)
            .then(function (response) {
                if (response.data.status === "success")
                    OvenPlayer.create('player_id', {
                        sources: response.data.sources,
                          /*  [
                            {
                                label: response.data.label,
                                // Set the type to 'webrtc'
                                type: response.data.type,
                                // Set the file to WebRTC Signaling URL with OvenMediaEngine
                                file: response.data.file
                            }
                        ]*/
                        waterMark: {
                            text: 'TRAMIX TV',
                            font: {
                                'font-size': '16px',
                                'color': '#fff',
                                'font-weight': 'bold'
                            },
                            position: 'top-right'
                        },
                        "autoStart": true,
                        "autoFallback": true,
                        "mute": false,
                    });
            })
            .catch(function (error) {

            })
            .then(function () {

            });
        // api get stream for id
       /* setPlayer(OvenPlayer.create('player_id', {
            sources: [
                {
                    label: 'label_for_webrtc',
                    // Set the type to 'webrtc'
                    type: 'webrtc',
                    // Set the file to WebRTC Signaling URL with OvenMediaEngine
                    file: 'ws://ome_host:signaling_port/app/stream'
                }
            ]
        }))*/


    });

   if (!user.isLogged) return <Redirect to="/login"/>




    return (
            <div className="player-wrapper">
                <div id="player_id"/>
            </div>
    );
}

export default Tv;
