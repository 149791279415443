import './App.css';
import {Container, Paper, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(10),
    },
}));

function Home() {
    const classes = useStyles();
    return (
        <div>
            <Container component="main">
                <Paper className={classes.paper}>
                    <Typography variant={"h3"}>Wersja beta B)</Typography>
                    <Typography variant={"h4"}>Błędy kierować na dc: tramix#1337</Typography>
                </Paper>
            </Container>
        </div>
    );
}

export default Home;
