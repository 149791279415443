import './App.css';
import {Button, Container, Paper, Snackbar, Toolbar, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "./App";
import {Redirect} from "react-router-dom";
import axios from "axios";
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(10),
    },
}));

function Twitch() {
    const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const [discordTwitch, setDiscordTwitch] = useState('')


    useEffect(() => {
        if (!user.isLogged) return;

        if (discordTwitch !== '') return;

        axios.get(`https://tramix.ovh/api/discord/getTwitch/${user.sessionId}`)
            .then(function (response) {
                if (response.data.status === "success")
                    setDiscordTwitch(response.data.twitchUsername)
            })
            .catch(function (error) {

            })
            .then(function () {

            });


    });

    if (!user.isLogged) return <Redirect to="/login"/>


    function connectTwitch() {
        axios.get(`https://tramix.ovh/api/discord/setTwitch/${user.sessionId}`)
            .then(function (response) {
                if (response.data.status === "success")
                    setUser({
                        ...user, user: {
                            id: user.user.id,
                            username: user.user.username,
                            avatar: user.user.avatar,
                            twitchAccount: discordTwitch
                        }
                    })
            })
            .catch(function (error) {

            })
            .then(function () {

            });
    }

    function unlinkTwitch() {
        axios.get(`https://tramix.ovh/api/discord/unlinkTwitch/${user.sessionId}`)
            .then(function (response) {
                if (response.data.status === "success")
                    setUser({
                        ...user, user: {
                            id: user.user.id,
                            username: user.user.username,
                            avatar: user.user.avatar,
                            twitchAccount: null
                        }
                    })
            })
            .catch(function (error) {

            })
            .then(function () {

            });
    }

    if (user.user.twitchAccount === null && discordTwitch !== '') {
        return (
            <Container component="main">
                <Paper className={classes.paper}>
                    <Typography variant={"h3"}><WarningIcon color={"warning"} fontSize={"inherit"}/> Nie połączyłeś
                        jeszcze swojego konta z botem!</Typography>
                    <Toolbar/>
                    <Typography variant={"h3"}>Masz połączone konto Twitch z Discordem. Chcesz je użyć?</Typography>
                    <Button variant="contained" onClick={() => connectTwitch()}>Użyj konta {discordTwitch}</Button>
                </Paper>
            </Container>

        )
    }
    if (user.user.twitchAccount === discordTwitch) {
        return (

            <Container component="main">
                <Paper className={classes.paper}>
                    <Typography variant={"h3"}><CheckCircleIcon color={"success"} fontSize={"inherit"}/> Połączyłeś
                        konto z botem!</Typography>
                    <Toolbar/>
                    <Typography variant={"h3"}>Chcesz je odłączyć?</Typography>
                    <Button variant="contained" onClick={() => unlinkTwitch()}>Odlącz konto {discordTwitch}</Button>
                </Paper>
            </Container>

        )
    }

    return (

        <Container component="main">
            <Paper className={classes.paper}>
                {/*nie ma polaczonego z botem ale ma polaczone z dc*/}
                <div>
                    <Typography variant={"h3"}><WarningIcon color={"error"} fontSize={"inherit"}/>Nie połączyłeś konta z
                        botem</Typography>
                    <Toolbar/>
                    <Typography variant={"h3"}>Połącz konto Twitch w aplikacji Discord i wróć pózniej! <Button component={"a"} href={"https://discord.com/settings/connections"} variant="contained" >Połącz konto</Button> </Typography>
                </div>
            </Paper>
        </Container>
    );
}

export default Twitch;
