import './App.css';
import {Container, Paper} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(10),
        color: "grey"
    },
}));

function Footer() {
    const classes = useStyles();
    return (
        <div>
            <Container component="main" maxWidth="xs">
                <Paper className={classes.paper} style={{color: "grey"}}>
                    2021 &copy; <a href={'https://discord.com/users/319471210662723584'} target={"_blank"} style={{color: 'inherit', textDecoration: "none"}} rel="noreferrer">tramix#1337</a>
                </Paper>
            </Container>
        </div>
    );
}

export default Footer;
