import React, {createContext, useEffect, useMemo, useState} from 'react';
import {Switch, Route} from "react-router-dom"
import Navbar from "./Navbar";
import Home from "./Home";
import Login from "./Login";
import {makeStyles} from "@mui/styles";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Leaderboard from "./Leaderboard";
import {Box, CircularProgress, Grid, Paper, Toolbar} from "@mui/material";
import {isMobile} from 'react-device-detect';
import Footer from "./Footer";
import Color from "./Color";
import Twitch from "./Twitch";
import axios from "axios";
import User from "./User";
import Tv from "./Tv";

const useStyles = makeStyles((theme) => ({
    nav: {

        // marginTop: '5vh',
        // paddingTop: '5vh'
        width: '70%',

    },
    mobile: {
        width: '95%',
            },
    centerdiv: {
        alignItems: "center"
    }
}));

//todo
// logowanie: zapisac token i id, stworzyc sessionid i przekazac go uzytkownikowi, sprawdzic czy sesja nadal istnieje inaczej wylogowac uzytkownika
// przy wejsciu na strone ustawic avatar i nick, ustawic w localStorage liste serverow na ktorych jest uzytkownik
// setCOlor podaje color akcent i session id
// na backendzie sprawdzana jest sesja: do jakiej jest przypisany uzytkownik i dla tego zmienic color
// po zalogowaniu leaderboard na glownej stronie wyswietla liste serverow gdzie user guilds === bot guilds
// logowanie twitch : z localstorage podac sesje wyslac uzytkownika na twitcha i wrocic do strony z odpowiedzia


export const ColorModeContext = createContext({ toggleColorMode: () => {} });


export const UserContext = createContext({
    user: '',
    setUser: () => {},
});

function App() {

    const [mode, setMode] = useState(localStorage.getItem("themeMode")!==null?localStorage.getItem("themeMode"):"dark");
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
                localStorage.setItem("themeMode",mode==="dark"?"light":"dark");
            },
        }),
        [mode],
    );

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: mode,
                    primary: {
                        main: '#4dd0e1',
                    },
                    secondary: {
                        main: '#ffab91',
                    },

                },
            }),
        [mode],
    );

    const [user, setUser] = useState(
        {user: {
                id:'',
                username:'',
                avatar:'',
                twitchAccount:''
            }       ,
            isLogged: false,
            sessionId: ''
        }

    )

    useEffect(() => {
        if (localStorage.getItem("session_id") !== null ) {
            if(user.isLogged) return;
            axios.get(`https://tramix.ovh/api/discord/session/${localStorage.getItem("session_id")}`)
                .then(function (res) {
                    if(res.data.status === "error" || res.data.userid === undefined) {
                        localStorage.removeItem("session_id");
                        window.location.reload(true);
                    }
                    else {
                        setUser({
                            ...user, user: {
                                id: res.data.userid,
                                username: res.data.username,
                                avatar: res.data.avatar,
                                twitchAccount:res.data.twitchname,
                            },
                            isLogged: true,
                            sessionId: res.data.session_id
                        })
                    }


                })
                .catch(function (error) {
                })
                .then(function () {
                });
        }
    }, [user]);



//todo ukryta strona easter egg
    // tv



    const data = useMemo(
        () => ({ user, setUser}),
        [user]
    );


    const classes = useStyles();
    return (
        <ColorModeContext.Provider value={colorMode}>
            <UserContext.Provider value={data}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    {(localStorage.getItem("session_id") !== null && !user.isLogged)?
                        (       <Box sx={{width: "100vw", height:"100vh", margin: "0 auto", display: "inline-flex", justifyContent: "space-evenly"}}>
                            <CircularProgress sx={{display:"block",margin: "0 auto"}} />
                        </Box>):(
                            <div className="App">
                                <Navbar/>
                                <Toolbar/>
                                <div className={classes.centerdiv}>
                                    <Grid container justifyContent="center">
                                        <Paper elevation={3} className={!isMobile?classes.nav:classes.mobile}>
                                            <Switch>
                                                <Route path="/login/:session?" component={Login}/>
                                                <Route path="/color/:color?/:accent?" component={Color}/>
                                                <Route path="/twitch/:action?" component={Twitch}/>
                                                <Route path="/leaderboard/:id?" component={Leaderboard}/>
                                                <Route path="/tv/:streamId" component={Tv}/>
                                                <Route exact={true} path="/user" component={User}/>
                                                <Route exact={true} path="/" component={Home}/>
                                            </Switch>
                                        </Paper>
                                    </Grid>
                                </div>
                                <Footer/>
                            </div>
                        )
                    }
                </ThemeProvider>
            </UserContext.Provider>
    </ColorModeContext.Provider>
    );
}

export default App;
