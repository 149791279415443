import './App.css';
import {Button, CircularProgress, Container, Paper, Tooltip, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link, Redirect, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import bg0 from './assets/bg0.png'
import bg1 from './assets/bg1.png'
import bg2 from './assets/bg2.png'
import bg3 from './assets/bg3.png'
import bg4 from './assets/bg4.png'

import a0 from './assets/exp0.png'
import a1 from './assets/exp1.png'
import a2 from './assets/exp2.png'
import a3 from './assets/exp3.png'
import a4 from './assets/exp4.png'


import axios from "axios";
import {UserContext} from "./App";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(10),
        padding: theme.spacing(4)
    },
}));




function Color() {
    const classes = useStyles();
    const {color, accent} = useParams();
    const {user} = useContext(UserContext);
    const [saving, setSaving] = useState(false);
    const [savingStatus, setSavingStatus] = useState("error")

    useEffect(() => {
        if((color !==undefined && (isNaN(parseInt(color)) || parseInt(color) > 4 || parseInt(color) < 0)) || (accent !== undefined && (isNaN(parseInt(accent)) || parseInt(accent) > 4 || parseInt(accent) < 0) ) ) return;

        axios.get(`https://tramix.ovh/api/discord/setcolor/${color}/${accent}/${user.sessionId}`)
            .then(function (response) {
                if(response.data.status === "success")
                    setSavingStatus("success")
                else
                    setSavingStatus("error")
            })
            .catch(function (error) {

            })
            .then(function () {

            });

        // setTimeout(() => {
        //     setSaving(false);
        // }, 1500);
    }, [accent, color, user.sessionId, user.sessionid]);


    if(!user.isLogged) return <Redirect to="/login" />


    if((color !==undefined && (isNaN(parseInt(color)) || parseInt(color) > 4 || parseInt(color) < 0)) || (accent !== undefined && (isNaN(parseInt(accent)) || parseInt(accent) > 4 || parseInt(accent) < 0) ) ) return (
            <div>
                <Container component="main">
                    <Paper className={classes.paper}>
                       <Typography style={{paddingTop: "0.3em"}} variant={"h3"}>NIE HACKUJ CHUJU</Typography>
                        <Toolbar/>
                        <Tooltip title="Zmień kolor i nie pajacuj">
                            <Button style={{margin: "1em"}} variant="contained" color="primary" component={Link} to={'/color'} xs={12}
                                        size={"large"}>Zmiana koloru</Button>
                        </Tooltip>
                    </Paper>
                </Container>
            </div>
        );

    if(color !==undefined && (parseInt(color) < 4 || parseInt(color) >= 0) && accent!== undefined && (parseInt(accent) < 4 || parseInt(accent) >= 0)) return (
        <div>
            <Container component="main">
                <Paper className={classes.paper}>
                    {saving ? (
                        <div>
                            <Typography variant={"h3"} marginY={1}>Ustawiam..</Typography>
                            <CircularProgress />
                        </div>

                    ) : (
                        savingStatus!=="error" ? (
                            <div>
                            <Typography variant={"h3"}><CheckCircleOutlineIcon color={"success"} fontSize={"inherit"} /> Kolor ustawiony pomyślnie</Typography>

                        </div>) : (

                            <div>
                                <Typography variant={"h3"}><ErrorOutlineTwoToneIcon color={"error"} fontSize={"inherit"} /> Bląd przy ustawianiu koloru</Typography>
                                <Toolbar/>
                                <Typography variant={"h4"}>Kontakt z administratorem</Typography>
                                <Typography variant={"h5"}>tramix#1337</Typography>

                            </div>
                        )

                    )

                    }

                </Paper>
            </Container>
        </div>
    );


    const accents = [
        {
            name: "Akcent numer #0",
            Image: a0,
            id: 0
        },
        {
            name: "Akcent numer #1",
            Image: a1,
            id: 1
        },
        {
            name: "Akcent numer #2",
            Image: a2,
            id: 2
        },
        {
            name: "Akcent numer #3",
            Image: a3,
            id: 3
        },
        {
            name: "Akcent numer #4",
            Image: a4,
            id: 4
        },
    ]


    function Accent(props)
    {
        return (<div>
                    <img width={"30%"} alt={props.item.name} src={props.item.Image}/>
                </div>
        )
    }


    if(color !==undefined && (parseInt(color) < 4 || parseInt(color) >= 0)) return (
        <div>
            <Container>
                <Paper className={classes.paper} >
                    <Typography style={{marginBottom: "0.5em"}} variant={"h3"}>WYBIERZ AKCENT</Typography>
                    { accents.map( (item) => <Link key={item.id} to={`/color/${color}/${item.id}`}><Accent item={item} component={Link} /></Link> ) }


                </Paper>
            </Container>
        </div>
    )



    const colors = [
        {
            name: "Tło numer #0",
            Image: bg0,
            id: 0
        },
        {
            name: "Tło numer #1",
            Image: bg1,
            id: 1
        },
        {
            name: "Tło numer #2",
            Image: bg2,
            id: 2
        },
        {
            name: "Tło numer #3",
            Image: bg3,
            id: 3
        },
        {
            name: "Tło numer #4",
            Image: bg4,
            id: 4
        },
    ]


    function ColorImgs(props)
    {
        return (<div>
                <img width={"50%"} alt={props.item.name} src={props.item.Image}/>
            </div>
        )
    }


    return (
        <div>
            <Container>
                <Paper className={classes.paper} >
                    <Typography style={{marginBottom: "0.5em"}} variant={"h3"}>WYBIERZ TŁO</Typography>

                    { colors.map( (item) => <Link key={item.id} to={`/color/${item.id}`}><ColorImgs key={item.id} item={item} component={Link} /></Link> ) }

                </Paper>
            </Container>
        </div>
    );
}

export default Color;
