import './App.css';
import { makeStyles } from '@mui/styles';
import {
    Avatar,
    Box,
    Card, CardHeader, CircularProgress, LinearProgress,
    Paper,
    Stack,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Toolbar,
    Typography
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import {Link,Redirect, useParams} from 'react-router-dom';
import React, {useContext, useEffect, useState} from "react";
import {cyan} from "@mui/material/colors";
import PropTypes from "prop-types";
import {UserContext} from "./App";

const axios = require('axios');

const useStyles = makeStyles((theme) => ({
    guild: {
        // marginTop: '5vh',
        // paddingTop: '5vh'
        // width: '40%',
        display: "inline-flex",
    },
    guild2: {
        // marginTop: '5vh',
        // paddingTop: '5vh'
        // width: '40%',
        margin: "1em",
        display: "inline-flex",
    },
    centerdiv: {
        alignItems: "center"
    }
}));
//todo dodac parametr do link
// https://tramix.tk/api/leaderboard/id
// router bez id wyswietla liste serverow

let level = [];

for (let i = 0; i < 500; i++) {
    level[i] = i < 10 ? 10 * i + 10 : i * i + 10;
}

export function getLevel(userexp) {
    for (let j = 0; j < 500; j++) {
        if (userexp <= level[j])
            return j + 1;
    }
    return 0;
}

export function getProcent(exp) {
    let lvl = getLevel(parseInt(exp));
    if(lvl >= 500) return 100;
    if(exp <= 10) {
        return exp*10;
    }else {
        let exp1 = level[lvl - 2];
        let exp2 = level[lvl - 1];
        let jeden = (exp - exp1) * 100;
        let dwa = exp2 - exp1
        return Math.round(jeden / dwa);
    }
}

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1}}>
                <LinearProgress {...props} sx={{animationDuration: "8s", height: '10px', borderRadius: '3px'}}/>
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

function Leaderboard() {
    const classes = useStyles();
    const {id} = useParams();
    const {user, setUser } = useContext(UserContext);
    const [data, setData] = useState([]);
    const [userGuilds, setUserGuilds] = useState([]);
    const [error, setError] = useState(false);
    const [barVariant, setBarVariant] = useState("indeterminate")
    useEffect(() => {




        if (id === undefined) {
            if(user.isLogged === true && userGuilds.length === 0){
                axios.get(`https://tramix.ovh/api/discord/guilds/${user.sessionId}`)
                    .then(function (response) {
                        setUserGuilds(response.data.guilds)
                    })
                    .catch(function (error) {
                        setError(true)
                    })
                    .then(function () {

                    });

            }


            return;

        }

        localStorage.setItem("lastGuild",id)
        if(data.length === 0){

            axios.get(`https://tramix.ovh/api/leaderboard/${id}`)
                .then(function (response) {
                    setData(response.data)
                })
                .catch(function (error) {
                    setError(true)
                })
                .then(function () {

                });

            setTimeout(() => {
                setBarVariant("determinate")
            }, 1500);
        }


    }, [data.length, id, user.isLogged, user.sessionId, userGuilds]);

    // if(data.length === 0 || userGuilds.length === 0) return (
    //     <Box>
    //         <Toolbar/>
    //         <CircularProgress />
    //         <Toolbar/>
    //     </Box>
    // )


    if(id === undefined) {
        if(localStorage.getItem("lastGuild") !==null && user.isLogged === false)
            return <Redirect to={`/leaderboard/${localStorage.getItem("lastGuild")}`} />

        if(localStorage.getItem("lastGuild") ===null && user.isLogged === false) return(
            <Box>
                <Typography variant="h2" component="div">
                    Zaloguj się aby wyświetlić listę serverów!
                </Typography>
            </Box>
        )
        return (
            <Box>
                <Typography variant="h2" component="div">
                    {userGuilds.map((guild) => (

                        <Card style={{ color: 'inherit', textDecoration: 'inherit'}} component={Link} to={`/leaderboard/${guild.guildid}`} className={classes.guild2}>
                            <Box sx={{display: 'flex'}}>
                                <Avatar variant="rounded"
                                        src={`https://cdn.discordapp.com/icons/${guild.guildid}/${guild.guild_logo}.${guild.guild_logo.startsWith("a_")?'gif':'png'}?size=256`}
                                        sx={{ width: 200, height: 200, margin:"1em"}}
                                />
                                <Stack spacing={1} marginX={5} marginY={2} style={{justifyContent: "center"}}>
                                    <Typography fontWeight={800}>{guild.guild_name}</Typography>
                                    <Typography variant="body2" color="text.secondary" style={{display:"inline-flex"}}>
                                        <PersonIcon sx={{color: cyan[500]}}/> {guild.members} members
                                    </Typography>
                                </Stack>
                            </Box>
                        </Card>

                    ))}
                </Typography>
            </Box>)

    }

    if(error) return (
        <Box>
            <Typography variant="h2" component="div">
                BŁĄD 429 TOO MANY REQUESTS
            </Typography>
        </Box>
    )
    if(data.length === 0) return (
        <Box>
            <Toolbar/>
            <CircularProgress />
            <Toolbar/>
        </Box>
    )

    return (
           <div>
                <Toolbar/>
                <Card className={classes.guild}>
                    <Box sx={{display: 'flex'}}>
                        <Avatar variant="rounded"
                                src={`https://cdn.discordapp.com/icons/${data.guild.id}/${data.guild.icon}.${data.guild.icon.startsWith("a_")?'gif':'png'}?size=256`}
                                sx={{ width: 200, height: 200, margin:"1em"}}
                        />
                        <Stack spacing={1} marginX={5} marginY={2} style={{justifyContent: "center"}}>
                            <Typography fontWeight={800}>{data.guild.name}</Typography>
                            <Typography variant="body2" color="text.secondary" style={{display:"inline-flex"}}>
                                <PersonIcon sx={{color: cyan[500]}}/> {data.guild.members} members
                            </Typography>
                        </Stack>
                    </Box>
                </Card>
                <Toolbar/>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 500 }} aria-label="leaderboard table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Rank</TableCell>
                                <TableCell align="left">Nick</TableCell>
                                <TableCell align="left">Level</TableCell>
                                <TableCell align="center">Progress</TableCell>
                                <TableCell align="center">Wiadomości</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.users.slice(0, 1).map((user) => (
                                <TableRow
                                    key={user.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center">{user.rank}</TableCell>
                                    <TableCell align="left"><CardHeader
                                        avatar={
                                            <Avatar
                                                alt={user.username}
                                                src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.${user.avatar.startsWith("a_")?'gif':'png'}?size=128`}
                                                sx={{ width: 64, height: 64 }}
                                            />
                                        }
                                        title={user.username}
                                    /></TableCell>
                                    <TableCell align="left">{getLevel(user.xp)}</TableCell>
                                    <TableCell align="center" sx={{width: "30%"}}><LinearProgressWithLabel color="secondary" variant={barVariant} value={getProcent(user.xp)} height={10} borderRadius={5} /></TableCell>
                                    <TableCell align="center">{user.messages}</TableCell>
                                </TableRow>
                            ))}
                            {data.users.slice(1, 2).map((user) => (
                                <TableRow
                                    key={user.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center">{user.rank}</TableCell>
                                    <TableCell align="left"><CardHeader
                                        avatar={
                                            <Avatar
                                                alt={user.username}
                                                src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.${user.avatar.startsWith("a_")?'gif':'png'}?size=128`}
                                                sx={{ width: 64, height: 64 }}
                                            />
                                        }
                                        title={user.username}
                                    /></TableCell>
                                    <TableCell align="left">{getLevel(user.xp)}</TableCell>
                                    <TableCell align="center" sx={{width: "30%"}}><LinearProgressWithLabel variant={barVariant} value={getProcent(user.xp)} height={10} borderRadius={5} /></TableCell>
                                    <TableCell align="center">{user.messages}</TableCell>
                                </TableRow>
                            ))}
                            {data.users.slice(2, 3).map((user) => (
                                <TableRow
                                    key={user.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center">{user.rank}</TableCell>
                                    <TableCell align="left"><CardHeader
                                        avatar={
                                            <Avatar
                                                alt={user.username}
                                                src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.${user.avatar.startsWith("a_")?'gif':'png'}?size=128`}
                                                sx={{ width: 64, height: 64 }}
                                            />
                                        }
                                        title={user.username}
                                    /></TableCell>
                                    <TableCell align="left">{getLevel(user.xp)}</TableCell>
                                    <TableCell align="center" sx={{width: "30%"}}><LinearProgressWithLabel variant={barVariant} value={getProcent(user.xp)} height={10} borderRadius={5} /></TableCell>
                                    <TableCell align="center">{user.messages}</TableCell>
                                </TableRow>
                            ))}
                            {data.users.slice(3).map((user) => (
                                <TableRow
                                    key={user.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center">{user.rank}</TableCell>
                                    <TableCell align="left"><CardHeader
                                        avatar={
                                            <Avatar
                                                alt={user.username}
                                                src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.${user.avatar.startsWith("a_")?'gif':'png'}?size=128`}
                                                sx={{ width: 64, height: 64 }}
                                            />
                                        }
                                        title={user.username}
                                    /></TableCell>
                                    <TableCell align="left">{getLevel(user.xp)}</TableCell>
                                    <TableCell align="center" sx={{width: "30%"}}><LinearProgressWithLabel variant={barVariant} value={getProcent(user.xp)} height={10} borderRadius={5} /></TableCell>
                                    <TableCell align="center">{user.messages}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
    );
}

export default Leaderboard;
