import './App.css';
import {Container, Paper, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useContext} from "react";
import {UserContext} from "./App";


const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(10),
    },
}));

function User() {
    const classes = useStyles();
    const {user} = useContext(UserContext);
    return (
        <div>
            <Container component="main">
                <Paper className={classes.paper}>
                    <Typography variant={"h3"}>ID : {user.user.id}</Typography>
                    <Typography variant={"h3"}>Username : {user.user.username}</Typography>
                    <Typography variant={"h3"}>Avatar : {user.user.avatar}</Typography>
                    <Typography variant={"h3"}>Twitch : {user.user.twitchAccount}</Typography>
                    <Typography variant={"h3"}>IsLogged : {user.isLogged?"true":"false"}</Typography>
                    <Typography variant={"h3"}>sessionid : {user.sessionId}</Typography>

                </Paper>
            </Container>
        </div>
    );
}

export default User;
