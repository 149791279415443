import React, {useContext} from 'react';
import {makeStyles} from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Avatar, Box, Button, CardHeader, IconButton, Tooltip} from "@mui/material";
import {Link, NavLink} from "react-router-dom";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PaletteIcon from '@mui/icons-material/Palette';
import { Icon } from '@iconify/react';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import {ColorModeContext, UserContext} from "./App";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: '5vh',
        alignItems: "center"
    },
    boxlogin: {
        marginLeft: 'auto',
    },
    toolbar: {
        width: '70%',
        alignItems: "center",
    },
    test: {
        width: '65vh'
    },
    appbar: {
        alignItems: "center"
    }

}));

export default function Navbar() {
    const classes = useStyles();
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);
    const {user, setUser } = useContext(UserContext);
    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appbar}>
                <Toolbar className={classes.toolbar}>

                        <Avatar component={Link} to={'/'} alt="Bloodtrail"
                                src="https://cdn.discordapp.com/avatars/683832725307654174/d416bb54bd00ed310c988ab7ca1a741c.webp?size=128"/>
                        <Typography variant="h6" component={Link} to={'/'}
                                    style={{paddingLeft: '10px', color: 'inherit', textDecoration: 'inherit'}}>
                            Bloodtrail Panel
                        </Typography>

                    <Box className={classes.boxlogin} sx={{ display: 'flex' }}>
                        <Tooltip title={theme.palette.mode === 'dark'?"Ustaw jasny tryb strony":"Ustaw dark mode strony"}>
                        <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color={"inherit"}>
                            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Zmień kolor plakietki">
                            <IconButton color={"inherit"} component={Link} to={'/color'} xs={12}
                                        size={"large"}><PaletteIcon/></IconButton>
                        </Tooltip>
                        <Tooltip title="Ranking serwerów">
                            <IconButton color={"inherit"} component={Link} to={'/leaderboard'} xs={12}
                                        size={"large"}><LeaderboardIcon/></IconButton>
                        </Tooltip>
                        <Tooltip title="Połącz swoje konto Twitch!">
                            <IconButton color={"inherit"} component={Link} to={'/twitch'} xs={12}
                                        size={"large"}><Icon icon="mdi:twitch" /></IconButton>
                        </Tooltip>
                        {user.isLogged ? (

                        //
                        //     <Avatar component={Link} to={'/user'} alt={user.user.username} xs={12}
                        //             src={`https://cdn.discordapp.com/avatars/${user.user.id}/${user.user.avatar}.${user.user.avatar.startsWith("a_")?'gif':'png'}?size=64`}/>
                        //     <Typography xs={12} variant="h6" component={Link} to={'/user'}
                        //     style={{paddingLeft: '10px', color: 'inherit', textDecoration: 'inherit'}}>
                        // {user.user.username}
                        //     </Typography>
                            <Tooltip title="Tajne informacje monkaS">
                                <Link to={'/user'} style={{ textDecoration: 'none', color: 'inherit'}}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            alt={user.user.username}
                                            src={`https://cdn.discordapp.com/avatars/${user.user.id}/${user.user.avatar}.${user.user.avatar.startsWith("a_")?'gif':'png'}?size=64`}
                                        />
                                    }
                                    title={user.user.username}
                                />
                                </Link>
                            </Tooltip>

                        ) : (<Button color={"inherit"} component={Link} to={'/login'} xs={12} size={"large"}>Login</Button>) }
                    </Box>

                </Toolbar>
            </AppBar>
        </div>
    );
}
