import {makeStyles} from "@mui/styles";
import axios from "axios";
import {Button, Container, Paper, Typography} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "./App";
import {Link} from "@mui/icons-material";
import { version as uuidVersion } from 'uuid';
import { validate as uuidValidate } from 'uuid';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(10)
    },
}));

function uuidValidateV4(uuid) {
    return uuidValidate(uuid) && uuidVersion(uuid) === 4;
}

function Login() {



    const classes = useStyles();
    const {session} = useParams();
    const {user, setUser } = useContext(UserContext);
    let history = useHistory();
    const [hackur, setHackur] = useState(false);

    useEffect(() => {

        if(session !== undefined)
       {
           if(session === "error" || !uuidValidateV4(session)){

               setHackur(true);
               return;
           }

           localStorage.setItem("session_id",session)


           axios.get(`https://tramix.ovh/api/discord/session/${session}`)
               .then(function (res) {
                   setUser({...user, user: {
                           id:res.data.userid,
                           username:res.data.username,
                           avatar:res.data.avatar,
                           twitchAccount:res.data.twitchname,
                       },
                       isLogged: true,
                       sessionId: session
                   })

               })
               .catch(function (error) {
               })
               .then(function () {
               });
            history.push('/')
       }


    }, [session, setUser, user]);



   return (

       <Container component="main">
           <Paper className={classes.paper}>

               {hackur ? (<Typography variant={"h3"}>Coś poszło nie tak <SentimentVeryDissatisfiedIcon fontSize={"inherit"} color={"error"}/></Typography> )
                        :
                   ( <Button sx={{margin: "5%"}} onClick={() => window.location.replace("https://tramix.ovh/api/discord/login")} variant="contained">Zaloguj przez Discord</Button>)
               }


           </Paper>
       </Container>
   )

}

export default Login;
